import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// Landing Page routing
const LandingPage = Loadable(lazy(() => import('views/landing-pages')));
const PricingPage = Loadable(lazy(() => import('views/landing-pages/Pricing')));
const TrialPage = Loadable(lazy(() => import('views/landing-pages/Trial')));
const BuySubscriptionPlanPage = Loadable(lazy(() => import('views/landing-pages/BuySubscriptionPlan')));
const PrivacyPolicyPage = Loadable(lazy(() => import('views/landing-pages/PrivacyPolicy')));
const RefundPolicyPage = Loadable(lazy(() => import('views/landing-pages/RefundPolicy')));
const SupportPage = Loadable(lazy(() => import('views/landing-pages/Support')));
const TermsAndConditionsPage = Loadable(lazy(() => import('views/landing-pages/TermsAndConditions')));
// ==============================|| MAIN ROUTING ||============================== //

const MinimalRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: '/pricing',
      element: <PricingPage />
    },
    {
      path: '/trial',
      element: <TrialPage />
    },
    {
      path: '/buy-subscription-plan',
      element: <BuySubscriptionPlanPage />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />
    },
    {
      path: '/refund-policy',
      element: <RefundPolicyPage />
    },
    {
      path: '/support',
      element: <SupportPage />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditionsPage />
    },
  ]
};

export default MinimalRoutes;